import React, { FC, useMemo, useState } from 'react';
import InfoItem from '@common/infoItem';
import { ModalChangeEmail } from '@common/modalChangeEmail';
import MoneyStatistic from '@common/moneyStatistic';
import { LEVEL_GRAND_AMBASSADOR, ROLE_AMBASSADOR, USER_STATUS_WAITING_CHANGE_EMAIL, } from '@src/constants';
import { checkAccessToAdmin } from '@src/helpers/checkAccessToAdmin';
import { enableAccessToIndicatorsPage } from '@src/helpers/checkAccessToElementPage';
import { useAppSelector } from '@state/store';
import classNames from 'classnames';
import Curator from '@common/curator';
import Hello from '@common/hello';
import Indicators from '@common/indicators';
import MobileHeader from '@common/mobileHeader';
// import Programs from '@common/programs';
import ShortStatistics from '@common/shortStatistics';
import ReferralsLinkTextBlock from "@common/referralsLinkTextBlock";

import "./styles.less";

interface IParams {
  waitingChangeEmail?: boolean;
}

const Root: FC<IParams> = ({ waitingChangeEmail = true }) => {
  const userInfo = useAppSelector((state) => state.user.info);

  const [openModal, setOpenModal] = useState(waitingChangeEmail && userInfo?.status_id === USER_STATUS_WAITING_CHANGE_EMAIL)

  const funds = useMemo(
    () => userInfo
      ?.company_funds
      ?.reduce(
        (obj: Record<string, number>, el) => {
          obj[el.code] = el.balance || 0;
          return obj;
        },
        {}
      ) || {},
    [userInfo?.company_funds]
  );

  const enableIndicatorsAndMoney = useMemo(
    () => enableAccessToIndicatorsPage(userInfo) || checkAccessToAdmin(userInfo),
    [userInfo]
  );

  const enableFunds = useMemo(
    () => userInfo?.role_id === ROLE_AMBASSADOR || checkAccessToAdmin(userInfo)
    , [userInfo]
  );
  // const enableStabFund = useMemo(
  //   () => enableFunds && Number(userInfo?.level || 0) >= LEVEL_GRAND_AMBASSADOR
  //   , [enableFunds, userInfo?.level]
  // );
  // const enableEvolutionFund = useMemo(
  //   () => [2, 4].includes(userInfo?.id ?? -999) || checkAccessToAdmin(userInfo)
  //   , [userInfo]
  // );
  // const enableSoulDropFund = useMemo(
  //   () => [6748, 3810, 92, 2, 4, 7374].includes(userInfo?.id ?? -999) || checkAccessToAdmin(userInfo)
  //   , [userInfo]
  // )

  const referralLinkBlock = useMemo(() => (
    <div className="info__card info-card-referral">
      <h2 className='mb-2 font-bold text-lg'>Реферальная ссылка</h2>
      <ReferralsLinkTextBlock qrcode={false} />
    </div>
  ), []);

  return (
    <main>
      <div className="wrap">
        <MobileHeader />
        <Hello />
        {/*<Programs />*/}

        <div className="main-content root">
          {enableIndicatorsAndMoney && (
            <div className="main-col">
              <Indicators />
              {referralLinkBlock}
            </div>
          )}

          <div className={enableIndicatorsAndMoney ? "main-col main-col-big" : "main-col-full"}>
            <ShortStatistics
              showIncome={false}
            />
            {enableIndicatorsAndMoney && (
              <MoneyStatistic />
            )}
            <div className={classNames("info gap-4 flex flex-row root", enableFunds ? '' : '!hidden')}>
              {enableFunds && funds?.['QUARTER_TRC_20'] && (
                <InfoItem
                  active={funds?.['QUARTER_TRC_20'] > 0}
                  value={Number(funds?.['QUARTER_TRC_20'] || 0).toFixed(1)}
                  title={["Дивидендный фонд", "(текущий баланс)"]}
                  // white={true}
                  className='fund-green'
                />
              )}
              {enableFunds && funds?.['STABLE_FOND_TRC_20'] && (
                <InfoItem
                  active={funds?.['STABLE_FOND_TRC_20'] > 0}
                  value={Number(funds?.['STABLE_FOND_TRC_20'] || 0).toFixed(1)}
                  title={["Стабилизационный фонд", "(текущий баланс)"]}
                  // white={true}
                  // className={classNames('fund-yellow', enableStabFund ? '' : '!hidden')}
                  className='fund-yellow'
                />
              )}
              {enableFunds && funds?.['EVOLUTION_TRC_20']
                ? (
                  <InfoItem
                    active={funds?.['EVOLUTION_TRC_20'] > 0}
                    value={Number(funds?.['EVOLUTION_TRC_20'] || 0).toFixed(1)}
                    title={["Фонд развития", "(текущий баланс)"]}
                    // white={true}
                    className='fund-cian'
                  />
                )
                : (
                  <InfoItem
                    active={userInfo?.isSubscribe}
                    value={userInfo?.isSubscribe ? "Да" : "Нет"}
                    title={["Право на", "вознаграждение",]}
                    white={true}
                  />
                )
              }
              {enableFunds && funds?.['SINGLE_PRODUCT_2_USDT_TRC_20'] && (
                <InfoItem
                  active={funds?.['SINGLE_PRODUCT_2_USDT_TRC_20'] > 0}
                  value={Number(funds?.['SINGLE_PRODUCT_2_USDT_TRC_20'] || 0).toFixed(1)}
                  title={["SoulDrop фонд (soft)", "(текущий баланс)"]}
                />
              )}
              {enableFunds && funds?.['SINGLE_PRODUCT_3_USDT_TRC_20'] && (
                <InfoItem
                  active={funds?.['SINGLE_PRODUCT_3_USDT_TRC_20'] > 0}
                  value={Number(funds?.['SINGLE_PRODUCT_3_USDT_TRC_20'] || 0).toFixed(1)}
                  title={["SoulDrop фонд (discord)", "(текущий баланс)"]}
                />
              )}
              {enableFunds && funds?.['SINGLE_PRODUCT_4_USDT_TRC_20'] && (
                <InfoItem
                  active={funds?.['SINGLE_PRODUCT_4_USDT_TRC_20'] > 0}
                  value={Number(funds?.['SINGLE_PRODUCT_4_USDT_TRC_20'] || 0).toFixed(1)}
                  title={["SoulDrop фонд (twitter)", "(текущий баланс)"]}
                />
              )}
              {enableFunds && funds?.['SINGLE_PRODUCT_5_USDT_TRC_20'] && (
                <InfoItem
                  active={funds?.['SINGLE_PRODUCT_5_USDT_TRC_20'] > 0}
                  value={Number(funds?.['SINGLE_PRODUCT_5_USDT_TRC_20'] || 0).toFixed(1)}
                  title={["SoulDrop фонд (proxy)", "(текущий баланс)"]}
                />
              )}
              {enableFunds && funds?.['SINGLE_PRODUCT_6_USDT_TRC_20'] && (
                <InfoItem
                  active={funds?.['SINGLE_PRODUCT_6_USDT_TRC_20'] > 0}
                  value={Number(funds?.['SINGLE_PRODUCT_6_USDT_TRC_20'] || 0).toFixed(1)}
                  title={["Подписка SoulDyor", "(текущий баланс)"]}
                  className='fund-souldyor'
                />
              )}
              {enableFunds && funds?.['SINGLE_PRODUCT_7_USDT_TRC_20'] && (
                <InfoItem
                  active={funds?.['SINGLE_PRODUCT_7_USDT_TRC_20'] > 0}
                  value={Number(funds?.['SINGLE_PRODUCT_7_USDT_TRC_20'] || 0).toFixed(1)}
                  title={["Мат.модель SoulDyor", "(текущий баланс)"]}
                  className='fund-souldyor'
                />
              )}
            </div>
            {/*<div className={classNames("info gap-4 flex flex-row root", enableFunds ? '' : '!hidden')}>
              {[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20].map((x) => (
                <InfoItem
                  active={funds?.['QUARTER_TRC_20'] > 0}
                  value={Number(funds?.['QUARTER_TRC_20'] || 0).toFixed(1)}
                  title={["Дивидендный фонд", "(текущий баланс)"]}
                  // white={true}
                  className={ 'fund-' + x.toString() }
                />
              ))}
            </div>*/}
            {!enableIndicatorsAndMoney
              ? (
                <div className="info gap-4 flex flex-row root">
                  <div>
                    {referralLinkBlock}
                  </div>
                  <Curator showTelegramLink={false} />
                </div>
              )
              : <Curator showTelegramLink={false} />
            }
          </div>
        </div>
      </div>

      <ModalChangeEmail openModal={openModal} closeModal={() => setOpenModal(false)} />
    </main>
  );
}

export default Root;
