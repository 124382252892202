import storage from '@services/storage';
import { md5 } from '@src/helpers';
import axios from 'axios';
import decode from 'jwt-decode';
import qs from 'qs';
import store from '@state/store';
import {
  jwtData, setClearUserExtraInfo, setGaVisible, setToken, setUserExtraInfo,
} from '@state/user';
import { setAppStage } from '@state/appStage';

import {
  APPSTAGE_AUTH,
  APPSTAGE_SIGNIN,
  AUTH_COOKIE_NAME,
  REFRESH_COOKIE_NAME,
  STORAGE_AUTH_BY_USER,
} from '@src/constants';

import config from '@src/config';
import { cloneDeep } from 'lodash';

axios.defaults.timeout = config.ajax_request_timeout;
const api_url = config.main_api_prefix;
const logger_url = config.logger_prefix;

let token;
let timerId;
let timerForDelTokenId;
const XDEBUG = 0;

class API {
  calculateControl = (dataToSend) => {
    const params = Object.keys(dataToSend).reduce((prev, key) => `${prev}${dataToSend[key]}`, '');
    const control = storage.get(REFRESH_COOKIE_NAME);
    return md5(`${params}${control}`);
  }
  async requestAsFormData(url, method = 'get', dataForSend = null, extraOpts = {}, extraHeaders = {}, external = false) {
    const formData = new FormData();
    Object.keys(dataForSend).forEach((key) => {
      if (dataForSend[key] !== undefined) {
        if (Array.isArray(dataForSend[key])) {
          dataForSend[key].forEach((x) => formData.append(key, x));
        } else if (typeof dataForSend[key] === 'object') {
          Object.keys(dataForSend[key]).forEach((x) => formData.append(`${key}[${x}]`, dataForSend[key][x]));
        } else {
          formData.append(key, dataForSend[key]);
        }
      }
    });
    if (Object.keys(dataForSend).length) {
      formData.append('control', this.calculateControl(dataForSend));
    }
    return this.request(
      url,
      method,
      formData,
      extraOpts,
      extraHeaders,
      external
    );
  }

  async loggerRequest(url, method = 'get', dataToSend = null, extraOpts = {}, extraHeaders = {}) {
    const newUrl = logger_url + url;
    return this.request(newUrl, method, dataToSend, extraOpts, extraHeaders, true);
  }

  async request(url, method = 'get', dataToSend = null, extraOpts = {}, extraHeaders = {}, external = false) {
    let answer;
    const requestUrl = external ? url : api_url + url;

    const noChangePage = dataToSend && dataToSend.noChangePage && dataToSend.noChangePage === true;
    if (noChangePage) {
      delete dataToSend.noChangePage;
    }
    let data;
    // if (method === 'get' && !external) {
    if (!external && !(dataToSend instanceof FormData)) {
      if (dataToSend) {
        //TODO: Переделать на вычисление контрольного числа всех параметров
        data = {...dataToSend, control: this.calculateControl(dataToSend)};
      } else {
        data = {};
      }
    } else {
      data = dataToSend;
    }

    const params = method === 'get' ? data : {};
    if (XDEBUG) {
      params.XDEBUG_SESSION_START = 1;
    }

    try {
      const opts = {
        method,
        url: requestUrl,
        headers: {
          ...extraHeaders,
        },
        data,
        params,
        ...extraOpts,
      };
      if (token) {
        opts.headers.Authorization = `Bearer ${token}`;
      }

      const response = await axios(opts);

      answer = {response};
    } catch (error) {
      answer = {response: error.response};
    }

    // Через 1 час удаляем токен, если не делаем запросов к БЭКу
    if (timerForDelTokenId) clearTimeout(timerForDelTokenId);
    timerForDelTokenId = setTimeout(() => storage.remove(AUTH_COOKIE_NAME), 1 * 60 * 60 * 1000);

    if (answer.response) {
      answer.headers = answer.response.headers;
      answer.status = answer.response.status;
      const answer_data = answer.response.data;
      answer.data = answer_data;
      answer.page = (
        data && data.page
          ? data.page
          : (
            data && data.offset !== undefined && data.limit !== undefined && data.limit
              ? Math.floor(data.offset / data.limit) + 1
              : 1
          )
      );

      if (answer.status === 401 && !external) {
        store.dispatch(setAppStage(APPSTAGE_SIGNIN));
      } else if (
        answer_data
        && answer_data.results
        && answer_data.results.length === 0
        && answer_data.count
        && params
        && params.limit
        && noChangePage !== true
      ) {
        const newData = cloneDeep(data);
        let newPage;
        if (data.offset && answer_data.count <= data.offset) {
          newPage = Math.floor(answer_data.count / data.limit) + 1;
        } else if (data.page && answer_data.count <= (data.page - 1) * data.limit) {
          newPage = data.page - 1;
        }
        newData.offset = (newPage - 1) * data.limit;
        newData.page = newPage;

        answer = await this.request(url, method, newData, extraOpts, extraHeaders, external);
      }
    } else {
      answer.status = -1;
      answer.data = null;
    }

    return answer;
  }

  getOptsForArray() {
    return {
      paramsSerializer: (params) => qs.stringify(params, {arrayFormat: 'repeat'}),
    };
  }

  getNullUuid = () => '00000000-0000-0000-0000-000000000000';

  login(email, password) {
    return this.requestAsFormData('/auth/login', 'post', {
      email,
      password,
    });
  }

  loginTg(tgParams = '') {
    return this.requestAsFormData(`/auth/login-tg`, 'post', {
      tgParams
    });
  }

  loginTgWithEmail(email, password, tgParams) {
    return this.requestAsFormData(`/auth/login-tg-with-email`, 'post', {
      email,
      password,
      tgParams,
    });
  }

  register(params) {
    return this.requestAsFormData('/auth/registration', 'post', params);
  }

  verifyEmail(token) {
    return this.request(`/auth/verify-email/${token}`, 'get' );
  }

  verifyEmailRepeat(email) {
    return this.requestAsFormData('/auth/verify-email-repeat', 'post', { email });
  }

  resetPassword(email) {
    return this.requestAsFormData('/auth/reset-password', 'post', { email });
  }

  verifyResetPassword(token) {
    return this.request(`/auth/verify-reset-password/${token}`, 'get' );
  }

  newPassword(params) {
    return this.requestAsFormData('/auth/new-password', 'post', params);
  }

  logout = async () => {
    const {status: status_refresh, data: data_refresh} = await this.request('/auth/refresh-token', 'delete');

    if (status_refresh === 200 && data_refresh?.status === 0) {
      const {status: status_logout, data: data_logout } = await this.request('/auth/logout', 'post');

      if (status_logout === 200 && data_logout?.status === 0) {
        return true;
      }
    }
    return false;
  }

  getTokenByRefresh() {
    return this.request('/auth/refresh-token', 'post');
  }

  refreshToken = async () => {
    const hasToken = !!storage.get(AUTH_COOKIE_NAME);

    if (hasToken) {
      const {status, data} = await this.getTokenByRefresh();

      if (status === 200) {
        this.setAUTHToken(data.token);
      } else {
        storage.remove(STORAGE_AUTH_BY_USER);
        store.dispatch(setAppStage(APPSTAGE_SIGNIN));
      }
    } else {
      storage.remove(STORAGE_AUTH_BY_USER);
      store.dispatch(setAppStage(APPSTAGE_SIGNIN));
    }
  }

  updateUserExtraInfo = async () => {
    const { status, data } = await api.getMyUser();

    if (status === 200) {
      const token = storage.get(AUTH_COOKIE_NAME);
      if (data.ga2fa && !token) {
        store.dispatch(setAppStage(APPSTAGE_SIGNIN));
        store.dispatch(setGaVisible({
          visible: true,
          procedure: "обновления данных пользователя",
          callBackOk: () => {
            store.dispatch(setUserExtraInfo(data));
            store.dispatch(setAppStage(APPSTAGE_AUTH));
          },
          callBackError: () => {
            store.dispatch(setAppStage(APPSTAGE_SIGNIN));
          }
        }));
      } else {
        store.dispatch(setUserExtraInfo(data));
        store.dispatch(setAppStage(APPSTAGE_AUTH));
      }
    } else {
      store.dispatch(setAppStage(APPSTAGE_SIGNIN));
    }
  }

  setAUTHToken(token_) {
    if (token_ && token_.length) {
      try {
        const decoded = decode(token_);

        token = token_;

        store.dispatch(jwtData(decoded));

        const current_date = +(new Date());
        const exp_date = decoded.exp * 1000;

        if (timerId) {
          clearTimeout(timerId);
        }

        timerId = setTimeout(
          () => this.refreshToken(),
          exp_date - current_date - 90 * 1000
        );

      } catch (e) {
        console.error({
          _f: 'API-setAUTHToken-218',
          e,
        });

        token = '';
        storage.remove(STORAGE_AUTH_BY_USER);
        if (timerId) {
          clearTimeout(timerId);
        }
      }
    } else {
      token = '';
      storage.remove(STORAGE_AUTH_BY_USER);
      if (timerId) {
        clearTimeout(timerId);
      }
    }

    store.dispatch(setToken(token));

    !token && store.dispatch(setClearUserExtraInfo());
  }

  getUserById(id) {
    return this.request(`/user/${id}`);
  }

  getMyUser() {
    return this.request(`/user`);
  }

  getProducts() {
    return this.request('/products');
  }

  buyProduct(id, walletId) {
    return this.request(`/products/buy/${walletId}/${id}`);
  }

  upgradeProduct(id, walletId) {
    return this.request(`/products/upgrade-to/${walletId}/${id}`);
  }

  getWalletById(id) {
    return this.request(`/wallet/get-wallet/${id}`);
  }

  updateWalletExternalAddress(params) {
    return this.requestAsFormData('/wallet/update-external-address', 'post', params);
  }

  checkAddress(walletId, address) {
    return this.request(`/wallet/check-address/${walletId}/${address}`);
  }

  createWithdrawal(params) {
    return this.requestAsFormData('/wallet/create-withdrawal', 'post', params);
  }

  verifyWithdrawal(token, iteration = 1, hash = undefined) {
    return this.requestAsFormData('/wallet/verify-withdrawal', 'post', { token, iteration, hash });
  }

  getLastWithdrawalAddress(walletId) {
    return this.request(`/wallet/get-last-user-withdrawal-address/${walletId}`);
  }

  getMarketing() {
    return this.request('/user/get-marketing');
  }

  getUserFirstLine(userId) {
    return this.request(`/user/get-first-line/${userId}`);
  }

  getUserTransactionListShort(wallet_id, count) {
    return this.request(`/user/get-transaction-list-short/${wallet_id}/${count}`);
  }

  getUserTransactionList(params = {}) {
    return this.requestAsFormData('/user/get-transaction-list', 'post', params);
  }

  updateUserInfo(params = {}) {
    return this.requestAsFormData('/user/update-user-info', 'post', params);
  }

  verifyUpdateUserInfo(token = {}) {
    return this.requestAsFormData('/user/verify-update-user-info', 'post', { token });
  }

  deleteUserInfo(params = {}) {
    return this.request('/user/delete-verification-user-info', 'post', params)
  }

  duplicateUserInfoMail(params = {}) {
    return this.request('/user/duplicate-user-info-mail', 'post', params)
  }

  sendFeedback(params = {}) {
    return this.requestAsFormData('/auth/send-feedback', 'post', params);
  }

  getProjects() {
    return this.request('/project');
  }

  getProjectInfo(id) {
    return this.request(`/project/get-info/${id}`);
  }

  closeProjectCurrentPull(id, params = {}) {
    return this.requestAsFormData(`/project/close-current-pull/${id}`, 'post', params);
  }

  getProjectCurrentPull(id) {
    return this.request(`/project/get-current-pull/${id}`);
  }

  getProjectPullList(id) {
    return this.request(`/project/get-pull-list/${id}`);
  }

  getProjectRules(id) {
    return this.request(`/project/get-rules/${id}`);
  }

  getProjectCommission(id) {
    return this.request(`/project/get-commission-list/${id}`);
  }

  getProjectUserCommission(id, params = {}) {
    return this.requestAsFormData(`/project/get-user-commission/${id}`, 'post', params);
  }

  newProjectRequest(id, params = {}) {
    return this.requestAsFormData(`/project/create/${id}`, 'post', params);
  }

  getProjectUserRequests(id) {
    return this.request(`/project/user-request-list/${id}`);
  }

  payProjectRequest(id, params = {}) {
    return this.requestAsFormData(`/project/buy/${id}`, 'post', params);
  }

  cancelProjectRequest(id, params = {}) {
    return this.requestAsFormData(`/project/cancel/${id}`, 'post', params);
  }

  setAllocateProjectPullResult(id, params = {}) {
    return this.requestAsFormData(`/project/allocate-result/${id}`, 'post', params);
  }

  setCancelProjectPull(id, params = {}) {
    return this.requestAsFormData(`/project/cancel-pull/${id}`, 'post', params);
  }

  generateUser2fa() {
    return this.request('/user/generate2fa');
  }

  verifyUser2fa(params = {}) {
    return this.requestAsFormData('/user/verify2fa', 'post', params);
  }

  validateUser2fa(params = {}) {
    return this.requestAsFormData('/user/validate2fa', 'post', params);
  }

  disableUser2fa() {
    return this.request('/user/disable2fa');
  }

  loginValidateUser2fa(params = {}) {
    return this.requestAsFormData('/auth/login-and-validate2fa', 'post', params);
  }

  authByUser(user_id) {
    // return this.requestAsFormData(`/user/upload-avatar`, 'post', params);
    return this.requestAsFormData('/user/auth-by-user', 'post', { user_id });
  }

  uploadUserAvatar(params = {}) {
    // return this.requestAsFormData(`/user/upload-avatar`, 'post', params);
    return this.request('/user/upload-avatar', 'post', params);
  }

  deleteUserAvatar() {
    return this.request('/user/delete-avatar');
  }

  getAllMoney() {
    return this.request('/user/get-all-money');
  }

  getAmbassadors() {
    return this.request('/auth/get-ambassadors');
  }

  getPromo(id) {
    return this.request(`/promo/${id}`);
  }

  getLeaderForMillion() {
    return this.request('/university/leader');
  }

  getMasterOfWord() {
    return this.request('/university/master');
  }

  getPolygonProof() {
    return this.request('/user/get-polygon-proof');
  }

  getPolygonInfo() {
    return this.request('/user/get-polygon-info');
  }

  updatePolygonNftToken(token_id, address) {
    return this.requestAsFormData('/user/update-polygon-nft-token', 'post', { token_id, address });
  }

  updatePolygonNftExternal(token_id, address) {
    return this.requestAsFormData('/user/update-polygon-external-nft', 'post', { token_id, address });
  }

  searchPolygonNftAddress(token_id, address) {
    return this.requestAsFormData('/user/search-polygon-nft-address', 'post', { token_id, address });
  }

  getReportUsersList(params = {}) {
    return this.request('/report/user-list', 'post', params);
  }

  getReportVolumeFirstLine(params = {}) {
    return this.request('/report/get-volume-first-line', 'get', params);
  }

  getReportVolumeUserList(params = {}) {
    return this.request('/report/get-volume-user-list', 'get', params);
  }

  updateReportVolumeUserList(user_list) {
    return this.requestAsFormData('/report/update-volume-user-list', 'post', { user_list });
  }

  getSingleProductPrice(single_product_id, currency_id) {
    return this.request('/single-product/get-price', 'get', { single_product_id, currency_id });
  }

  buySingleProduct(params = {}) {
    return this.requestAsFormData('/single-product/buy', 'post', params);
  }

  getReportUsersSales(params = {}) {
    return this.request('/report/get-users-sales', 'post', params)
  }

  getReportLevelRaise(params = {}) {
    return this.request('/report/get-level-raise', 'post', params)
  }

  updateWantToNextFlow(params = {}) {
    return this.request('/user/want-to-next-flow', 'post', params)
  }

  updateWantRepeatToNextFlow(params = {}) {
    return this.request('/user/want-to-repeat-next-flow', 'post', params)
  }

  getReportUserCuratorList(params = {}) {
    return this.request('/report/get-user-curator-list', 'get', params)
  }

  getReportNextFlow(params = {}) {
    return this.request('/report/get-next-flow', 'get', params)
  }

  getCurrentFlow(params = {}) {
    return this.request('/report/get-current-flow', 'get', params)
  }

  updateNextFlow(params = {}) {
    return this.request('/user/update-next-flow', 'post', params)
  }

  getEnrollmentProgress(params = {}) {
    return this.request('/report/get-today-enrollment-progress', 'get', params)
  }

  updateSendPayToEduByIdsSelfStudy(params = {}) {
    return this.request('/user/send-pay-to-edu-by-ids-self-study', 'post', params)
  }

  deleteMetaMask(params = {}) {
    return this.request('/user/delete-meta-mask', 'delete', params)
  }

  getUserInfo(params = {}) {
    return this.request('/report/get-user-info', 'post', params)
  }

  getUsersSavedToFlow(params = {}) {
    return this.request('/report/get-users-saved-to-flow', 'post', params)
  }

  disableNextFlow(params = {}) {
    return this.request('/user/disable-want-next-flow', 'post', params)
  }

  checkUserDataDuplicate(params = {}) {
    return this.request('/user/check-data-duplicate', 'post', params)
  }

  checkEmptyAccount(params = {}) {
    return this.request('/user/check-empty-account', 'post', params)
  }

  deleteEmptyAccount(params = {}) {
    return this.request('/user/delete-empty-account', 'post', params)
  }

  createUserOnEDU(params = {}) {
    return this.request('/user/create-user-on-edu', 'post', params)
  }

  checkDuplicateEmail(params = {}) {
    return this.request('/user/check-duplicate-email', 'post', params)
  }

  changeUserInfo(params = {}) {
    return this.request('/user/change-user-info', 'post', params)
  }

  getCompareFlow(params = {}) {
    return this.request('/report/compare-flow', 'get', params)
  }

  changeEmail(params = {}) {
    return this.request('/user/change-email', 'post', params)
  }

  changeLevel(params = {}) {
    return this.request('/user/change-user-level', 'post', params)
  }

  buyTRX(params = {}) {
    return this.request('/system/buy-trx', 'post', params)
  }

  payForServers(params = {}) {
    return this.request('/system/pay-for-servers', 'post', params)
  }

  buyProductForUserByEmail(params = {}) {
    return this.request('/system/buy-product-for-user-by-email', 'post', params)
  }

  userChangeStatus(params = {}) {
    return this.request('/user/change-user-status', 'post', params)
  }

  getReportUserProductList(params = {}) {
    return this.request('/report/get-user-product-list', 'get', params);
  }

  setUserTgAuthOnPartners(params = {}) {
    return this.request('/auth/set-user-tg-auth-on-partners', 'post', params);
  }

  getSingleProductSalesList(params = {}) {
    return this.request('/report/single-product-sales-list', 'get', params);
  }
}

const api = new API();

export default api;
