import {withTranslation} from "react-i18next";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import React, {Component} from "react";
import {DECIMAL_SCALE, ROUTE_REPORT} from "@src/constants";
import api from "@services/api";
import MobileHeader from "@common/mobileHeader";
import CatalogHeader from "@common/catalogHeader";
import {DatePicker, message, Select, Space, Table} from "antd";
import ExportCSV from "@common/exportCSV/component";
import dayjs from "dayjs";
import advancedFormat from 'dayjs/plugin/advancedFormat'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import localeData from 'dayjs/plugin/localeData'
import weekday from 'dayjs/plugin/weekday'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import weekYear from 'dayjs/plugin/weekYear'
import {NumericFormat} from "react-number-format";

import "./styles.less";

dayjs.extend(customParseFormat)
dayjs.extend(advancedFormat)
dayjs.extend(weekday)
dayjs.extend(localeData)
dayjs.extend(weekOfYear)
dayjs.extend(weekYear)

class SingleProductSalesPage extends Component {

    state = {
        saleList: [],
        loading: false,
        dateStart: dayjs('26/02/2025', 'DD/MM/YYYY'),
        dateEnd: dayjs(),
        singleProduct: 6,
        products: [],
    }

    componentDidMount() {
        this.getProductSales()
    }

    getProductSales = async () => {
        const { dateStart, dateEnd, singleProduct } = this.state;

        this.setState({loading: true});

        const {status, data} = await api.getSingleProductSalesList({
            date_start: dateStart.format('YYYY-MM-DD'),
            date_end: dateEnd.format('YYYY-MM-DD'),
            single_product_id: singleProduct
        });

        if (status === 200 && data?.status === 0) {
            this.setState({
                saleList: data?.data?.sales,
                products: data?.data?.products,
                dateStart: dayjs(data.date_start, 'YYYY-MM-DD'),
                dateEnd: dayjs(data.date_end, 'YYYY-MM-DD'),
            });
        } else {
            message.warning(data?.message ?? 'Ошибка сервера')
        }

        this.setState({loading: false});
    }

    onChange = (dates) => {
        this.setState({
            saleList: [],
            dateStart: dayjs(dates[0], 'YYYY-MM-DD'),
            dateEnd: dayjs(dates[1], 'YYYY-MM-DD')
        }, async () => await this.getProductSales())
    }

    changeProduct = (value) => {
        this.setState({
            saleList: [],
            singleProduct: value
        }, async () => await this.getProductSales())
    }

    columns = [
        {
            title: 'Дата',
            dataIndex: 'date',
            key: 'date',
            align: 'center',
            render: (date) => (
              <div className="flex flex-row gap-1 column-row-block justify-center">
                  <div className="block lg:hidden column-title-block">{date ? 'Дата:' : ''}</div>
                  <div className="column-content-block st-table-imfo type column_type">{date ?? 'ИТОГО'}</div>
              </div>
            ),
        },
        {
            title: 'Количество',
            dataIndex: 'count',
            key: 'count',
            align: 'center',
            render: (count = 0) => (
              <div className="flex flex-row gap-1 column-row-block justify-center">
                  <div className="block lg:hidden column-title-block">Количество:</div>
                  <div className="column-content-block st-table-imfo type column_type">{count}</div>
              </div>
            ),
        },
        {
            title: 'Сумма',
            dataIndex: 'sum',
            key: 'sum',
            align: 'center',
            render: (sum = 0, record) => (
              <div className="flex flex-row gap-1 column-row-block justify-center">
                  <div className="block lg:hidden column-title-block">Сумма:</div>
                  <div className="column-content-block st-table-imfo type column_type">
                      <NumericFormat
                        value={sum}
                        displayType={'text'}
                        decimalScale={DECIMAL_SCALE}
                        fixedDecimalScale
                        thousandSeparator=' '
                        className="!inline !opacity-100 !text-sm"
                      />&nbsp;{record?.currency}
                  </div>
              </div>
            ),
        },
    ]

    render() {
        const { t } = this.props;
        const  {saleList, loading, products, singleProduct, dateStart, dateEnd} = this.state;
        const { RangePicker } = DatePicker;

        return (
            <main className="report-single-product-sales-page">
                <div className="wrap">
                    <MobileHeader/>
                    <CatalogHeader
                        pageName={t('LEFT_MENU_REPORT/SINGLE-PRODUCT-SALES')}
                        parentPageName={t('LEFT_MENU_REPORT')}
                        parentPageLink={ROUTE_REPORT}
                        withPaySubscription={false}
                    />
                    <div className="flex flex-col gap-1">
                        <form className="static__header static__headerm flex flex-col gap-4">
                            <div className="static__head-block">
                                <div className="flex flex-col gap-1">
                                    <div className="flex flex-row gap-1 flex-wrap">
                                        <Select
                                          showSearch
                                          defaultValue={6}
                                          options={products.map(item => {
                                              return {label: item.name, value: item.id}
                                          })}
                                          onChange={(value) => this.changeProduct(value)}
                                        />
                                        <div className="">
                                            <Space>
                                                <RangePicker
                                                  allowClear={false}
                                                  value={[dayjs(this.state.dateStart), dayjs(this.state.dateEnd)]}
                                                  onCalendarChange={(moment, dates) => {this.onChange(dates)}}
                                                  allowEmpty={[false, false]}
                                                />
                                            </Space>
                                        </div>
                                        <ExportCSV
                                          columns={this.columns}
                                          getData={()=>{return saleList}}
                                          fileName='Отчет - Продажи ед. Продуктов'
                                        >
                                          Экспорт CSV
                                        </ExportCSV>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <div className="static__contents !py-4">
                            <div className="static__cont">
                                <Table
                                    dataSource={saleList}
                                    columns={this.columns}
                                    loading={loading}
                                    rowKey={(record, id) => (
                                      ''.concat(
                                        (singleProduct?.toString() ?? '-1'),
                                        '-',
                                        (dateStart.format('YYYY-MM-DD') ?? '-1'),
                                        '-',
                                        (dateEnd.format('YYYY-MM-DD') ?? '-1'),
                                        '-',
                                        (id?.toString() ?? '-1')
                                      )
                                    )}
                                    rowClassName="st-table-line flex-col lg:flex-row !items-start lg:items-center w-full gap-1 lg:gap-0"
                                    className='static-table'
                                    pagination={false}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        );
    }
}

const mapStateToProps = (state) => ({
    userInfo: state.user.info,
    userFullInfo: state.user,
});

export default connect(mapStateToProps)(
    withTranslation()(
        withRouter(
          SingleProductSalesPage
        )
    )
);
